import React, { lazy } from 'react';
import LoadingPage from 'layouts/LoadingPage';
import { useSelector } from 'react-redux';
import { Navigate, Routes, Route } from 'react-router-dom';
import { FAMILY_URL } from 'config/settings';
import Motivate from 'components/Motivate';
import MotivatePage from 'pages/MotivatePage';
import useTrackPageChange from 'hooks/useTrackPageChange';
import TopicFlowPage from 'components/flows/TopicFlowPage';
import AssistantFlowPage from 'components/flows/AssistantFlowPage';
import { FlowTypeToSlug } from 'components/flows/helpers/flowItemHelpers';
import StudentManagementMoved from 'components/students/StudentManagementMoved';
import Onboarding from 'components/onboarding/Onboarding';
import OnboardingIntro from 'components/onboarding/OnboardingIntro';
import OnboardingQuestion from 'components/onboarding/OnboardingQuestion';
import Schools from 'components/school/Schools';
import SchoolSearch from 'components/school/SchoolSearch';
import SchoolAddForm from 'components/school/SchoolAddForm';
import SchoolJoin from 'components/school/SchoolJoin';
import JoinClass from 'components/joinClass/JoinClass';
import Home from 'components/Home';
import OnboardingConstants from 'components/onboarding/OnboardingConstants';
import OnboardingAssignment from 'components/onboarding/OnboardingAssignment';
import SignupLayout from 'layouts/SignupLayout';
import GroupLeague from 'pages/leagues/GroupLeague';
import useDynamicFeature from 'hooks/useDynamicFeature';

const GrowthPage = lazy(() => import('pages/reports/GrowthPage'));
const SchoolReportsPage = lazy(() => import('pages/reports/SchoolReportsPage'));
const SchoolLeague = lazy(() => import('pages/leagues/SchoolLeague'));
const InviteCode = lazy(() => import('components/auth/InviteCode'));
const Unsubscribe = lazy(() => import('components/unsubscribe/Unsubscribe'));
const LoginRedirect = lazy(() => import('components/auth/LoginRedirect'));
const Login = lazy(() => import('components/auth/Login'));
const ForgotPassword = lazy(() => import('components/auth/ForgotPassword'));
const ResetPassword = lazy(() => import('components/auth/ResetPassword'));
const UserRolePicker = lazy(() => import('components/auth/UserRolePicker'));
const SignUp = lazy(() => import('components/auth/SignUp'));
const ConfirmCredentials = lazy(() =>
  import('components/auth/ConfirmCredentials')
);
const Logout = lazy(() => import('components/auth/Logout'));
const Results = lazy(() => import('pages/Results'));
const Dashboard = lazy(() => import('components/Dashboard'));
const Topics = lazy(() => import('components/Topics'));
const RetrievalPractice = lazy(() =>
  import('components/retrievalPractice/RetrievalPractice')
);
const Students = lazy(() => import('pages/Students'));
const StudentsList = lazy(() => import('components/students/StudentsList'));
const StudentManagementContainer = lazy(() =>
  import('components/management/students/StudentManagementContainer')
);
const AddStudentsToClassesModal = lazy(() =>
  import('components/management/students/AddStudentsToClassesModal')
);
const ManageStudentClassesModal = lazy(() =>
  import('components/management/students/ManageStudentClassesModal')
);
const ClassLeague = lazy(() => import('pages/students/ClassLeague'));
const Student = lazy(() => import('pages/Student'));
const StudentOverview = lazy(() =>
  import('components/students/StudentOverview')
);
const StudentTopicsList = lazy(() =>
  import('components/students/StudentTopicsList')
);
const StudentProfile = lazy(() => import('components/students/StudentProfile'));
const Analytics = lazy(() => import('pages/Analytics'));
const ClassOverview = lazy(() => import('components/analytics/ClassOverview'));
const Settings = lazy(() => import('pages/Settings'));
const Classes = lazy(() => import('components/classes/Classes'));
const TeacherManagementContainer = lazy(() =>
  import('components/management/teachers/TeacherManagementContainer')
);
const AddTeachersToClassesModal = lazy(() =>
  import('components/management/teachers/AddTeachersToClassesModal')
);
const ManageTeacherClassesModal = lazy(() =>
  import('components/management/teachers/ManageTeacherClassesModal')
);
const EditClassCardContainer = lazy(() =>
  import('components/classes/EditClassCardContainer')
);
const Account = lazy(() => import('pages/Account'));
const CollectionsView = lazy(() =>
  import('components/collectionSearch/CollectionsView')
);
const CollectionQuizPage = lazy(() =>
  import('components/collectionSearch/CollectionQuizPage')
);
const ClassNotifications = lazy(() =>
  import('components/classes/ClassNotifications')
);
const TeacherUploads = lazy(() => import('components/teacher/TeacherUploads'));
const Notifications = lazy(() =>
  import('components/notifications/Notifications')
);
const ConfigManagement = lazy(() =>
  import('components/configmanagement/ConfigManagement')
);
const TeacherProfile = lazy(() => import('components/profile/TeacherProfile'));
const TeacherPending = lazy(() => import('components/teacher/TeacherPending'));
const JoinClassContainer = lazy(() =>
  import('components/classes/JoinClassContainer')
);
const ModalLayout = lazy(() => import('layouts/ModalLayout'));
const EditClassTeachersContainer = lazy(() =>
  import('components/classes/EditClassTeachersContainer')
);
const EditClassStudentsContainer = lazy(() =>
  import('components/classes/EditClassStudentsContainer')
);
const MoveStudents = lazy(() => import('components/teacher/MoveStudents'));
const StyleGuide = lazy(() => import('components/StyleGuide'));
const Layout = lazy(() => import('layouts/Layout'));
const SignupRoute = lazy(() => import('./SignupRoute'));
const PrivateRoute = lazy(() => import('./PrivateRoute'));
const PageNotFound = lazy(() => import('components/errors/PageNotFound'));
const Switch = lazy(() => import('components/classes/Switch'));
const AssignmentResults = lazy(() =>
  import('components/results/AssignmentResults')
);
const TeacherIndependentTopicResults = lazy(() =>
  import('components/results/IndependentStudy/TeacherIndependentTopicResults')
);
const ParentsPage = lazy(() =>
  import('components/classes/Parents/ParentsPage')
);
const ReportsPage = lazy(() => import('pages/reports/ReportsPage'));
const GroupInsightReportsPage = lazy(() =>
  import('pages/reports/GroupReportsPage')
);
const StudentPredictionsPage = lazy(() =>
  import('pages/studentPredictions/StudentPredictionsPage')
);
const SyncPage = lazy(() => import('../pages/sync/SyncPage'));
const OAuthLoginPage = lazy(() => import('components/auth/OAuthLoginPage'));

// b2b routes
const QuizView = lazy(() => import('components/b2bPages/QuizView'));
const QuizList = lazy(() => import('components/b2bPages/QuizList'));

const AppRoutes = () => {
  useTrackPageChange();
  const userState = useSelector(s => s.user.userState);
  const { schoolId, country } = useSelector(s => ({
    schoolId: s.school.schoolId,
    country: s.school.country ?? s.school.currentSchool?.country
  }));

  const { isFeatureEnabled } = useDynamicFeature({
    isTracking: true,
    action: 'Might See Handheld Assignment Page',
    isABTesting: true,
    dynamicFeature: 'HandheldAssignmentPage'
  });

  // Redirect paths.
  const relativePath = window.location.pathname + window.location.search;
  const redirectRelativePath = relativePath.replace(/^\/+/, ''); // Remove any leading '/'

  const showHandheldAssignmentPage =
    isFeatureEnabled &&
    !userState[OnboardingConstants.OnboardingAssignmentCreated];

  return (
    <React.Suspense fallback={<LoadingPage />}>
      <Routes>
        {/* Redirect any old route */}
        <Route path="/c" element={<Navigate to="/" replace />} />
        <Route
          path="/signup/new/teacher"
          element={<Navigate to="/signup/teacher" replace />}
        />
        <Route
          path="/signup/new/student"
          element={<Navigate to="/signup/student" replace />}
        />
        <Route
          path="/signup/teacher/choice"
          element={<Navigate to="/signup/teacher" replace />}
        />
        <Route
          path="/:schoolId/collections/:collectionId?"
          element={
            <Navigate to={`/${schoolId}?modal=%2Fset-work%2Ftopics`} replace />
          }
        />
        <Route
          path="/:schoolId/teachers"
          element={<Navigate to={`/${schoolId}/settings/teachers`} replace />}
        />
        <Route
          path="/:schoolId/classes"
          element={<Navigate to={`/${schoolId}/settings`} replace />}
        />
        {/* Redirect family routes to Eedi Family (including the family school IDs) */}
        <Route
          path="/tutor"
          element={
            <Navigate to={`${FAMILY_URL}/${redirectRelativePath}`} replace />
          }
        />

        <Route path="/" index element={<Home />} />

        <Route path="/unsubscribe" element={<Unsubscribe />} />
        <Route path="/loginredirect" element={<LoginRedirect />} />

        <Route element={<SignupRoute />}>
          <Route element={<SignupLayout />}>
            <Route path="/login" element={<Login />} />
            <Route path="/login/forgot-password" element={<ForgotPassword />} />
            <Route
              path="/login/reset-password/:resetCode"
              element={<ResetPassword />}
            />
            <Route path="/signup" element={<UserRolePicker />} />
            <Route path="/signup/teacher" element={<SignUp />} />
            <Route path="/signup/:userRole" element={<InviteCode />} />
            <Route
              path="/signup/:userRole/invite-code"
              element={<InviteCode />}
            />
            <Route path="/signup/:userRole/new" element={<SignUp />} />

            <Route path="/signup/confirm" element={<ConfirmCredentials />} />
          </Route>
        </Route>

        <Route path={'/quizzes'} element={<QuizList />} />
        <Route path="/quizzes/:shortCode" element={<QuizView />} />

        <Route
          path="/flow-template/:templateSlug/:flowType/:topicPathwayQuizId?/:questionSequence?"
          element={<TopicFlowPage />}
        />

        <Route
          path="/topic-session/:flowGeneratorSessionId"
          element={<TopicFlowPage />}
        />

        <Route element={<PrivateRoute />}>
          <Route
            element={
              <ModalLayout>
                <Layout />
              </ModalLayout>
            }>
            <Route path="/assistant" element={<AssistantFlowPage />} />
            <Route
              path="/topic/dr/:topicPathwayQuizId/:flowGeneratorSessionId?"
              element={<TopicFlowPage templateSlug={FlowTypeToSlug.DR} />}
            />
            <Route
              path="/topic/d/:topicPathwayQuizId/:flowGeneratorSessionId?"
              element={<TopicFlowPage templateSlug={FlowTypeToSlug.D} />}
            />
            <Route
              path="/topic/drsm/:topicPathwayQuizId/:flowGeneratorSessionId?"
              element={<TopicFlowPage templateSlug={FlowTypeToSlug.DRSM} />}
            />
            <Route
              path="/quiz-session/:quizSessionId"
              element={<TopicFlowPage />}
            />

            {country === 'United Kingdom' && (
              <Route path="/:schoolId/quizzes" element={<QuizList />} />
            )}
            <Route path="/:schoolId" element={<Results />}>
              <Route
                path=""
                element={
                  showHandheldAssignmentPage ? (
                    <OnboardingAssignment />
                  ) : (
                    <Dashboard />
                  )
                }
              />
              <Route path="assignments" element={<Dashboard />} />
              <Route path="independent-study" element={<Topics />} />
              <Route
                path="retrieval-practice/:tab?"
                element={<RetrievalPractice />}
              />
            </Route>

            <Route
              path="/:schoolId/assignments/:assignmentId/:tab?/:questionSequence?/*"
              element={<AssignmentResults />}
            />
            <Route
              path="/:schoolId/independent-study/:quizId/:tab?/:questionSequence?"
              element={<TeacherIndependentTopicResults />}
            />

            {/* STUDENTS */}
            <Route path="/:schoolId/students" element={<Students />}>
              <Route path="" element={<StudentsList />} />
              <Route path="baseline" element={<StudentPredictionsPage />} />
              <Route path="manage" element={<StudentManagementMoved />} />
            </Route>

            <Route path="/:schoolId/motivate" element={<MotivatePage />}>
              <Route path="" element={<Motivate />} />
              <Route path="class-league" element={<ClassLeague />} />
              <Route path="school-league" element={<SchoolLeague />} />
              <Route path="group-league" element={<GroupLeague />} />
            </Route>

            <Route
              path="/:schoolId/students/:studentId/*"
              element={<Student />}>
              <Route path="" element={<StudentOverview />} />
              <Route path="topics" element={<StudentTopicsList />} />
              <Route path="profile" element={<StudentProfile />} />
            </Route>

            {/* ANALYTICS */}
            <Route path="/:schoolId/analytics" element={<Analytics />}>
              <Route path="" element={<ClassOverview />} />
            </Route>

            {/* Reports */}
            <Route path="/:schoolId/reports" element={<ReportsPage />}>
              <Route path="" element={<GroupInsightReportsPage />} />
              <Route path="growth" element={<GrowthPage />} />
              <Route path="school" element={<SchoolReportsPage />} />
            </Route>

            {/* SETTINGS */}

            <Route path="/:schoolId/settings" element={<Settings />}>
              <Route path="" element={<Classes />} />
              <Route path="teachers" element={<TeacherManagementContainer />}>
                <Route
                  path="add-teacher-classes"
                  element={<AddTeachersToClassesModal />}
                />
                <Route
                  path="manage-teacher-classes"
                  element={<ManageTeacherClassesModal />}
                />
              </Route>
              <Route
                path="students/:groupId"
                element={<StudentManagementContainer />}
              />
              <Route path="students" element={<StudentManagementContainer />}>
                {[':groupId/add-student-classes', 'add-student-classes'].map(
                  (path, i) => (
                    <Route
                      key={i}
                      path={path}
                      element={<AddStudentsToClassesModal />}
                    />
                  )
                )}
                {[
                  ':groupId/manage-student-classes',
                  'manage-student-classes'
                ].map(path => (
                  <Route
                    key={path}
                    path={path}
                    element={<ManageStudentClassesModal />}
                  />
                ))}
              </Route>
              <Route path="sync" element={<SyncPage />} />
              <Route path="parents/:groupId?" element={<ParentsPage />} />
              <Route
                path="classes/:groupId"
                element={<EditClassCardContainer />}
              />
            </Route>

            {/* SET WORK */}
            <Route
              path="/:schoolId/collections/:collectionId"
              element={<CollectionsView />}
            />
            <Route
              path="/:schoolId/quizzes/:shortCode"
              element={<CollectionQuizPage />}
            />

            <Route
              path="/:schoolId/classes/notifications"
              element={<ClassNotifications />}
            />

            <Route path="/:schoolId/uploads" element={<TeacherUploads />} />

            <Route
              path="/:schoolId/notifications"
              element={<Notifications />}
            />

            <Route path="/config" element={<ConfigManagement />} />

            <Route
              path="/:schoolId/teachers/:id"
              element={<TeacherProfile />}
            />
          </Route>

          <Route
            path="/:schoolId/pending"
            element={
              <ModalLayout>
                <TeacherPending />
              </ModalLayout>
            }
          />

          <Route path="/welcome" element={<Onboarding />}>
            <Route path="" element={<OnboardingIntro />} />
            <Route path="question" element={<OnboardingQuestion />} />
          </Route>

          <Route path="/schools" element={<Schools />}>
            <Route path="" element={<SchoolSearch />} />
            <Route path="add" element={<SchoolAddForm />} />
            <Route path="join" element={<SchoolJoin />} />
          </Route>

          <Route
            path="/:schoolId/classes/add/*"
            element={
              <ModalLayout>
                <JoinClassContainer />
              </ModalLayout>
            }
          />

          <Route path="/:schoolId/classes/new/*" element={<JoinClass />} />

          <Route
            path="/:schoolId/settings/classes/:groupId/teachers/edit"
            element={<EditClassTeachersContainer />}
          />
          <Route
            path="/:schoolId/classes/:groupId/students/edit"
            element={<EditClassStudentsContainer />}
          />
          <Route
            path="/:schoolId/classes/:groupId/students/move"
            element={<MoveStudents />}
          />
          <Route
            path="/:schoolId/classes/:groupId/:path?"
            element={<Switch />}
          />
          <Route
            path="/:schoolId/classes/:groupId/switch"
            element={<Switch />}
          />
        </Route>
        <Route path="/oauth-login" element={<OAuthLoginPage />} />
        <Route path="/logout" element={<Logout />} />
        <Route path="/account" element={<Account />} />
        <Route path="/style-guide" element={<StyleGuide />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </React.Suspense>
  );
};

export default AppRoutes;

import './globals';
import { BrowserRouter } from 'react-router-dom';
import { ApolloProvider } from '@apollo/react-hooks';
import { PersistGate } from 'redux-persist/es/integration/react';
import { Provider } from 'react-redux';
import client from 'config/apolloClient';
import ErrorBoundary from 'components/errors/ErrorBoundary';
import AppRoutes from 'routes/AppRoutes';
import { persistor, store } from './store';
import { ConfirmicProvider } from '@confirmic/react';
import AppProviders from 'providers/AppProviders';
import { LessonHubSignalRProvider } from 'providers/LessonHubSignalRProvider';
import ScrollToTop from 'components/common/ScrollToTop';

const confirmicProjectId = 'prj:7b604390-6ce5-4865-bcce-356a87267605';

const App = () => (
  <ApolloProvider client={client}>
    <Provider store={store}>
      <LessonHubSignalRProvider>
        <PersistGate loading={null} persistor={persistor}>
          <ConfirmicProvider projectId={confirmicProjectId}>
            <BrowserRouter>
              <ScrollToTop />
              <ErrorBoundary>
                <AppProviders>
                  <AppRoutes />
                </AppProviders>
              </ErrorBoundary>
            </BrowserRouter>
          </ConfirmicProvider>
        </PersistGate>
      </LessonHubSignalRProvider>
    </Provider>
  </ApolloProvider>
);

export default App;

import Cookies from 'js-cookie';
import jwt from 'jsonwebtoken';

const env = 'prod' || process.env.NODE_ENV;

let apiUrl = process.env.REACT_APP_API_URL || 'https://api-qa.eedi.com'; // 'https://localhost:5001' ;
let graphqlUrl =
  process.env.REACT_APP_GRAPHQL_URL || 'https://graphql-qa.eedi.co.uk/graphql';
let omrFunctionsApiUrl =
  process.env.REACT_APP_OMR_FUNCTIONS_API_URL || 'https://omr-api-qa.eedi.com';
let stripeApiKey = 'pk_test_yFbC08JVbfxTN3XcErgUkY9i';
let familyUrl =
  process.env.REACT_APP_FAMILY_URL || 'https://family-qa.eedi.com';
let teacherUrl =
  process.env.REACT_APP_TEACHER_URL || 'https://teacher-qa.eedi.com';
let functionsApiUrl =
  process.env.REACT_APP_FUNCTIONS_API_URL ||
  'https://eedi-functions-api-qa.azurewebsites.net';
let flowApiUrl =
  process.env.REACT_APP_FLOW_API_URL || 'https://flow-api-qa.eedi.com';

if (env === 'prod') {
  apiUrl = 'https://api.eedi.com';
  graphqlUrl = 'https://graphql.eedi.co.uk/graphql';
  functionsApiUrl =
    process.env.REACT_APP_FUNCTIONS_API_URL ||
    'https://eedi-functions-api-prod.azurewebsites.net';
  omrFunctionsApiUrl = 'https://omr-api.eedi.com';
  stripeApiKey = 'pk_live_pypeIsnPgZnGRFxT0peYl4X5';
  familyUrl = 'https://family.eedi.com';
  teacherUrl = 'https://teacher.eedi.com';
  flowApiUrl = 'https://flow-api.eedi.com';
}

// Export these settings and allow them to be overridden by local .env files
export const API_URL = apiUrl;
export const GRAPHQL_URL = graphqlUrl;
export const FUNCTIONS_API_URL = functionsApiUrl;
export const OMR_FUNCTIONS_API_URL = omrFunctionsApiUrl;
export const FAMILY_URL = familyUrl;
export const TEACHER_URL = teacherUrl;
export const FLOW_API_URL = flowApiUrl;
export const ENVIRONMENT = env;

// The BUILD_ID is the Azure Dev ops build identifier.
export const BUILD_ID = 'eedi_teacher_prod_buildid_13358'|| 'Development';

export const DISABLE_ANALYTICS =
  process.env.REACT_APP_DISABLE_ANALYTICS || false;

export const STRIPE_API_KEY =
  process.env.REACT_APP_STRIPE_API_KEY || stripeApiKey;

export const IMAGE_URL =
  process.env.REACT_APP_IMAGE_URL || 'https://images.diagnosticquestions.com';

export const MARKETING_SITE_URL =
  process.env.REACT_APP_MARKETING_SITE_URL || 'https://eedi.com';

export const GOOGLE_AUTH_CLIENT_ID = // Google Classroom API Client ID
  process.env.GOOGLE_AUTH_CLIENT_ID ||
  '525871439613-13usjc4adu4ft6qr3gq3sul3dug6coac.apps.googleusercontent.com';

export const CLEVER_CALLBACK_URL = API_URL + '/v3/clever-login-callback';

export const CLEVER_CLIENT_ID =
  env === 'prod' ? '13eeffcb842e4660df96' : '5f4242f5e2ec9b137a53';

export const CLEVER_SSO_URL = `https://clever.com/oauth/authorize?response_type=code&redirect_uri=${CLEVER_CALLBACK_URL}&client_id=${CLEVER_CLIENT_ID}`;

/**
 * @desc - Returns the imageUrl parsed against the environment variables.
 * @param string $imageUrl - The imageUrl to be parsed.
 * @return string - The parsed imageUrl.
 */
export const getImageUrl = src => {
  let imageUrl = src !== null && src !== undefined ? `${IMAGE_URL}/${src}` : '';
  if (imageUrl.length) {
    if (IMAGE_URL.length === 0 || src.indexOf('http') !== -1) {
      imageUrl = src;
    }
  }
  return imageUrl.replace(/([^:]\/)\/+/g, '$1');
};

export const getAuthToken = () => {
  return localStorage.getItem('authToken');
};

export const setAuthToken = token => {
  localStorage.setItem('authToken', token);
  Cookies.set('authToken', token, {
    path: '/',
    domain: '.eedi.com',
    secure: true,
    sameSite: 'strict'
  });
};

export const isAuthenticated = () => {
  const authToken = getAuthToken();
  if (!authToken) {
    return false;
  }

  const decodedAuthToken = jwt.decode(authToken);
  const tokenExpiresAt = new Date(decodedAuthToken?.exp * 1000);
  return !isNaN(tokenExpiresAt.getTime()) && tokenExpiresAt > new Date();
};

export const removeAuthToken = () => {
  Cookies.remove('authToken');
};

if (ENVIRONMENT === 'development') {
  console.log('*************** DEVELOPMENT ****************');
  console.log(`API: ${API_URL}`);
  console.log(`GraphQL Server: ${GRAPHQL_URL}`);
  console.log('********************************************');
}

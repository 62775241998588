export const FlowTypeToSlug = {
  D: 'TopicPathwayQuiz_NoLessons_v2023-05-11',
  DR: 'TopicPathwayQuiz_v2023-07-19',
  DRSM: 'TopicPathwayQuiz_ChoiceFlow_AM_v2023-10-19'
};

export const FlowTypes = {
  topicPathwayQuiz: 'TopicPathwayQuiz',
  challenge: 'Challenge',
  generic: 'Generic',
  neurips: 'Neurips',
  chatWithTutor: 'ChatWithTutor',
  topicPathwayQuizQuestion: 'TopicPathwayQuizQuestion',
  questionOfTheDay: 'QuestionOfTheDay',
  onDemandSupport: 'OnDemandSupport',
  retrievalPracticeAssignment: 'RetrievalPracticeAssignment',
  teacherAssistant: 'TeacherAssistant'
};

export const FlowItemType = {
  // Simple text message
  Text: 'Text',

  // Message that contains an image to be displayed
  Image: 'Image',

  // Message that contains a video to be displayed
  Video: 'Video',

  // Message that comes with predefined responses to choose from
  RadioButtonInput: 'RadioButtonInput',

  // Message that should be responded to with free text
  TextInput: 'TextInput',
  TextInputExplanation: 'TextInputExplanation',

  // Message that should be responded to with free text OR file upload
  UploadInput: 'UploadInput',

  // A message representing a horizontal divider
  Divider: 'Divider',

  // Contains a file that can be downloaded
  File: 'File',

  // Represents quoted text
  HighlightedText: 'HighlightedText',

  // Information on something that's happened. e.g. "TUTOR left the lesson"
  Information: 'Information',

  DiagnosticQuestionAnswerInput: 'DiagnosticQuestionAnswerInput',
  DiagnosticQuestionRetryInput: 'DiagnosticQuestionRetryInput',

  PinnedImage: 'PinnedImage',
  PinnedVideo: 'PinnedVideo',
  PinnedText: 'PinnedText'
};

export const renderableMessageTypes = [
  FlowItemType.Information,
  FlowItemType.HighlightedText,
  FlowItemType.Image,
  FlowItemType.PinnedImage,
  FlowItemType.Video,
  FlowItemType.PinnedVideo,
  FlowItemType.Divider,
  FlowItemType.File
];

export const testFlowItems = [
  {
    flowItemId: '1',
    flowGeneratorSessionId: 175294,
    userId: 1290836,
    flowItemGroup: null,
    position: -1,
    sequence: -1,
    dateDispatched: '2023-11-08T20:46:42.9441191Z',
    text: 'Is 2182 a prime!!',
    imageUrl: '',
    videoUrl: '',
    fileUrl: null,
    radioButtons: null,
    flowItemType: FlowItemType.Text,
    contentType: 'Default',
    milliSecondsDelay: 0
  },
  {
    flowItemId: '2',
    flowGeneratorSessionId: 175294,
    userId: -1,
    flowItemGroup: null,
    position: -1,
    sequence: -1,
    dateDispatched: '2023-11-08T20:46:42.9441191Z',
    text: 'Question 2) Starting at 7,549, \n fill in the blanks to count forwards in steps of 3., ____, ____, ____,',
    imageUrl: '',
    videoUrl: '',
    fileUrl: null,
    radioButtons: null,
    flowItemType: FlowItemType.Text,
    contentType: 'Default',
    milliSecondsDelay: 0
  },
  {
    flowItemId: '2',
    flowGeneratorSessionId: 175294,
    userId: -1,
    flowItemGroup: null,
    position: -1,
    sequence: -1,
    dateDispatched: '2023-11-08T20:46:42.9441191Z',
    text: 'checkout helpdesk here: https://help.eedi.co.uk',
    imageUrl: '',
    videoUrl: '',
    fileUrl: null,
    radioButtons: null,
    flowItemType: FlowItemType.Text,
    contentType: 'Default',
    milliSecondsDelay: 0
  },
  {
    flowItemId: '3',
    flowGeneratorSessionId: 175294,
    userId: 1290836,
    flowItemGroup: null,
    position: -1,
    sequence: -1,
    dateDispatched: '2023-11-08T20:46:42.9441191Z',
    text: 'Is 2182 a prime!!',
    imageUrl: '',
    videoUrl: '',
    fileUrl: null,
    radioButtons: null,
    flowItemType: FlowItemType.HighlightedText,
    contentType: 'Default',
    milliSecondsDelay: 0
  },
  {
    flowItemId: '4',
    flowGeneratorSessionId: 175294,
    userId: -1,
    flowItemGroup: null,
    position: -1,
    sequence: -1,
    dateDispatched: '2023-11-08T20:46:42.9441191Z',
    text: 'That is good to know!',
    imageUrl: '',
    videoUrl: '',
    fileUrl: null,
    radioButtons: null,
    flowItemType: FlowItemType.HighlightedText,
    contentType: 'Default',
    milliSecondsDelay: 0
  },
  {
    flowItemId: '47228a14-387f-4903-8f73-79f010456ee5',
    flowGeneratorSessionId: 175298,
    userId: 0,
    flowItemGroup: 'Intro2',
    position: 1,
    sequence: 2,
    dateDispatched: '2023-11-14T12:14:25.4856411Z',
    text: 'Some text!',
    imageUrl: 'https://home-content.eedi.com/web/intro3.gif',
    videoUrl: '',
    fileUrl: null,
    radioButtons: [],
    flowItemType: 'Image',
    contentType: 'Default',
    milliSecondsDelay: 200
  },
  {
    flowItemId: '4fdb7543-8349-40b3-80f0-d13df58e1407',
    flowGeneratorSessionId: 175298,
    userId: 0,
    flowItemGroup: 'CheckInQuestionDivider',
    position: 2,
    sequence: 5,
    dateDispatched: '2023-11-15T10:40:17.5160936Z',
    text: 'Stage 1 - Check In',
    imageUrl: '',
    videoUrl: '',
    fileUrl: null,
    radioButtons: [],
    flowItemType: 'Divider',
    contentType: 'Default',
    milliSecondsDelay: 200
  },
  {
    flowItemId: '4d3b0643-e3fe-4056-877f-0ec9875c288e',
    flowGeneratorSessionId: 175298,
    userId: -1,
    flowItemGroup: null,
    position: 1,
    sequence: -1,
    dateDispatched: '2023-11-15T14:07:09.8248245Z',
    text: 'James has left the lesson',
    imageUrl: '',
    videoUrl: '',
    fileUrl: null,
    radioButtons: null,
    flowItemType: 'Information',
    contentType: 'Default',
    milliSecondsDelay: 0
  },
  {
    flowItemId: 'ebcad694-d433-4d2c-9c53-72a5602579a7',
    flowGeneratorSessionId: 175298,
    userId: 0,
    flowItemGroup: 'LessonLearnTalkingTeacherExplanationVideo_9_1_4',
    position: 4,
    sequence: 44,
    dateDispatched: '2023-11-15T10:58:12.2333399Z',
    text: '',
    imageUrl: '',
    videoUrl:
      'https://home-content.eedi.com/eedi/constructs/9/teach/1b-talking-teacher.mp4',
    fileUrl: null,
    radioButtons: [],
    flowItemType: 'PinnedVideo',
    contentType: 'Default',
    milliSecondsDelay: 200
  },
  {
    flowItemId: '4536ad14-c2f2-4708-b563-33afce8df9b2',
    flowGeneratorSessionId: 175298,
    userId: 0,
    flowItemGroup: 'LessonLearnTalkingTeacherExplanationVideo_9_1_4',
    position: 4,
    sequence: 45,
    dateDispatched: '2023-11-17T10:58:12.234351Z',
    text: '',
    imageUrl: '',
    videoUrl: '',
    fileUrl: null,
    radioButtons: [
      {
        sequence: 1,
        value: '✅ Yes, move on',
        label: '✅ Yes, move on',
        labelOptions: ['✅ Yes, move on']
      },
      {
        sequence: 2,
        value: '🤔 Yes, watch video anyway',
        label: '🤔 Yes, but unsure',
        labelOptions: ['🤔 Yes, but unsure']
      },
      {
        sequence: 3,
        value: '❓ Not quite',
        label: '❓ Not quite',
        labelOptions: ['❓ Not quite']
      }
    ],
    flowItemType: 'RadioButtonInput',
    contentType: 'Video',
    milliSecondsDelay: 200
  }
];

export const diagnosticQuestionRadionOptions = [
  {
    sequence: 1,
    value: 'A',
    label: 'A',
    labelOptions: ['A']
  },
  {
    sequence: 2,
    value: 'B',
    label: 'B',
    labelOptions: ['B']
  },
  {
    sequence: 3,
    value: 'C',
    label: 'C',
    labelOptions: ['C']
  },
  {
    sequence: 4,
    value: 'D',
    label: 'D',
    labelOptions: ['D']
  }
];

import IntercomAppProvider from './IntercomAppProvider';
import AnalyticsProvider from 'analytics/AnalyticsProvider';
import LoadingPage from 'layouts/LoadingPage';
import { ConsentGate } from '@confirmic/react';
import useSetUserContext from 'hooks/useSetUserContext';

const AppProviders = ({ children }) => {
  const { loading } = useSetUserContext();

  if (loading) {
    return <LoadingPage />;
  }

  return (
    <>
      {children}
      <IntercomAppProvider />
      <ConsentGate micropolicy="statistics">
        <AnalyticsProvider />
      </ConsentGate>
    </>
  );
};

export default AppProviders;
